import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['taskType']

    connect() {
    }

    setType (e) {
        this.taskTypeTarget.value = e.target.selectedOptions[0].dataset.type;
    }
}