import { Controller } from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";

export default class extends Controller {
    static targets = [ "inputField", "label", "inputGroup", "labelGroup", "destroyElement" ];
    static values = {
        editing: { type: Boolean, default: false },
        checklistId: {type: String, default: ""}
    };

    connect() {
        this.editingValue ? this.startEditing() : this.finishEditing();
    }

    startEditing(e) {
        this.labelTarget.innerHTML = this.inputFieldTarget.value;
        this.labelGroupTarget.classList.add('hidden');
        this.inputGroupTarget.classList.remove('hidden');
        this.inputFieldTarget.focus();
        this.inputFieldTarget.select();
    }

    finishEditing(e) {
        this.inputFieldTarget.value = this.labelTarget.innerText;
        this.inputGroupTarget.classList.add('hidden');
        this.labelGroupTarget.classList.remove('hidden');
    }

    cancel(e) {
        this.finishEditing();
    }

    loseFocus() {
        setTimeout(()=>{
            this.finishEditing();
        }, 100)
    }


    save() {
        this.labelTarget.innerHTML = this.inputFieldTarget.value;
        this.finishEditing();
    }

    destroy() {
        this.element.classList.add('hidden');
        this.destroyElementTarget.value = true;
    }

    async addTask(checklist_id) {
        const request = new FetchRequest("POST", `/staff/action_items/add_checklist_task?checklist_id=${checklist_id}`,{responseKind: "turbo-stream"});
        const response = await request.perform();
        if (response.ok) {
            setTimeout(() => this.loading = false, 200)
        }
    }
}
