// DATA CONTROLLER
// data-controller="checkbox"

// INDIVIDUAL CHECKBOX ROW EXAMPLE
// <input type="checkbox" value="<%= action_item.id %>" class="checkbox" data-checkbox-target="checkbox" data-action="click->checkbox#toggle_checkbox">

//POPUP CHECKBOX FORM
// data-checkbox-target="form"

// SEND CHECKED IDS TO CONTROLLER ACTION EXAMPLE
// <div class="btn-md btn-primary" data-action="click->checkbox#submit" data-url="<%= change_dates_staff_action_items_path %>" data-response-kind="turbo_stream">Move to Tomorrow</div>

// COUNT EXAMPLE
// <span data-checkbox-target="count">1</span>

// CLEAR ALL BUTTON EXAMPLE
// <div class="btn-md btn-text" data-action="click->checkbox#unsetCheckboxes">Clear</div>

// SELECT ALL BUTTON & SELECT ALL CHECKBOX EXAMPLE
// <div class="btn-md btn-text" data-action="click->checkbox#selectVisibleCheckboxes">Select All</div>
// <input type="checkbox" class="checkbox" data-checkbox-target="checkAllCheckbox" data-action="click->checkbox#toggleSelectAll">

// TO REDIRECT
// <div class="btn-md btn-primary" data-action="click->checkbox#submit" data-url="<%= change_dates_staff_action_items_path %>" data-response-kind="json">Move to Tomorrow</div>
//     respond_to do |format|
//       format.json { render json: { url: root_path } }
//     end


import { Controller } from "@hotwired/stimulus"
import {FetchRequest, post} from "@rails/request.js";

const DEFAULT_RESPONSE_KIND = 'turbo_stream';

export default class extends Controller {
    static targets = ["form", "checkbox", "pagy", "count", "checkAllCheckbox", "modal"]
    checkedIds = [];
    formVisible = false;

    connect() {
        //set checked_ids from params
        const urlParams = new URLSearchParams(window.location.href);
        if (urlParams.has("checked_ids")) {
            this.checkedIds = urlParams.getAll("checked_ids");
            this.checkedIds = this.checkedIds.join().split(','); // convert array elements to strings
        }

        if (this.hasCheckboxTarget) { this.setCheckboxes(); }
        this.updateCount();
    }

    // Checks checkboxes based on 'checked_ids' Array
    setCheckboxes() {
        let count = 0;

        // checks all individual checkboxes
        for (const checkbox of this.checkboxTargets) {
            if (this.checkedIds.includes(checkbox.value.toString())) {
                checkbox.checked = true;
                count++;
            } else {
                checkbox.checked = false;
            }
        }

        if (this.hasCheckAllCheckboxTarget) { this.updateCheckAllCheckbox(count); }
        this.updateCount();
        this.updateFormVisibility();
        if (this.hasPagyTarget) { this.updatePagy(); }
    }

    // checked 'check all' checkbox
    updateCheckAllCheckbox(count) {
        if (count == this.checkboxTargets.length) {
            this.checkAllCheckboxTarget.checked = true;
        } else {
            this.checkAllCheckboxTarget.checked = false;
        }
    }

    // Unchecks ALL checkboxes
    unsetCheckboxes() {
        this.checkedIds = [];
        this.setCheckboxes();
        this.resetUrl();
    }

    // Removes 'checked_ids' from current address bar url
    resetUrl() {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete('checked_ids');
        window.history.replaceState(null, null, window.location.pathname + '?' + searchParams.toString());
    }

    // Toggles all visible checkboxes on / off
    toggleSelectAll(event) {
        const el = event.target;

        if (el.checked) {
            this.selectVisibleCheckboxes();
        } else {
            this.unSelectVisibleCheckboxes();
        }

        this.setCheckboxes();
    }

    selectVisibleCheckboxes() {
        for (const checkbox of this.checkboxTargets) {
            if (!this.checkedIds.includes(checkbox.value.toString())) {
                this.checkedIds.push(checkbox.value.toString());
            }
        }
        this.setCheckboxes();
    }

    unSelectVisibleCheckboxes() {
        for (const checkbox of this.checkboxTargets) {
            this.checkedIds = this.checkedIds.filter(arrayItem => arrayItem !== checkbox.value.toString());
        }
    }

    // Toggles clicked checkbox on / off
    toggle_checkbox(event) {
        const el = event.target;
        const checkboxId = el.value;

        if(el.checked) {
            this.checkedIds.push(checkboxId.toString());
        } else {
            this.checkedIds = this.checkedIds.filter(arrayItem => arrayItem !== checkboxId);
        }

        this.setCheckboxes();
    }

    // Append checked checkbox values (record ids) to pagy links
    updatePagy() {
        const pagyLinks = this.pagyTarget.getElementsByTagName("a");

        for (const pagyLink of pagyLinks) {
            let url = new URL(pagyLink.href);
            if (this.checkedIds.length > 0) {
                url.searchParams.set('checked_ids', this.checkedIds);
            } else {
                url.searchParams.delete('checked_ids');
            }
            pagyLink.href = url;
        }
    }

    // Send post of checked_ids to url in data-url of target element
    async submit (event) {
        const el = event.target;

        if (!el.dataset.url) return; //return if url hasn't been set
        const responseKind = el.dataset.responseKind ? el.dataset.responseKind : DEFAULT_RESPONSE_KIND;
        console.log(responseKind);

        const data = new FormData();
        data.append('checked_ids', this.checkedIds);

        const request = new FetchRequest("post", el.dataset.url,{ body: data, responseKind: responseKind });
        const response = await request.perform();

        if (response.ok) {
            setTimeout(()=>{
                this.setCheckboxes();
            }, 100);

            if(responseKind == 'json') {
                const json = await response.json;
                Turbo.visit(json.url);
            }
        }

        this.closeModal();
    }

    updateCount() {
        this.countTarget.innerHTML = this.checkedIds.length;
    }

    updateFormVisibility() {
        if (!this.hasFormTarget) { return; }

        if (this.checkedIds.length > 0 && this.formVisible == false) {
            this.formTarget.classList.remove('hidden');
        } else if (this.checkedIds.length == 0) {
            this.formTarget.classList.add('hidden');
        }
    }

    openModal(event) {
        if (!this.hasModalTarget) { return; }

        this.modalTarget.classList.remove('hidden');
    }

    closeModal(event) {
        if (!this.hasModalTarget) { return; }

        this.modalTarget.classList.add('hidden');
    }
}
