import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [ "input", "filterable", "link", "parent" ];
    static values = { filterParent: { type: Boolean, default: false } }

    connect() {
        this.filter()
    }

    filter(event) {
        let filterBy = this.inputTarget.value.toLowerCase();

        this.filterableTargets.forEach((el, i) => {
            let filterableString = el.getAttribute("data-filter-string");

            let hide = filterableString.includes(filterBy);
            el.classList.toggle("hidden", !hide);
        })

        //Append filter_value as query param to links
        this.linkTargets.forEach((element) => {
            let url = new URL(element.href);
            let params = new URLSearchParams(url.search);

            params.set("filter_value", filterBy);
            const new_url = new URL(`${url.origin}${url.pathname}?${params}`);
            element.href = new_url.href;
        });

        if (this.filterParentValue) { this.filterParent(); }
    }

    filterParent() {
        this.parentTargets.forEach((parentElement) => {
            let childElements = parentElement.querySelectorAll(`:not(.hidden)[data-filter-target*=\"filterable\"]`);

            let hide = childElements.length == 0;
            parentElement.classList.toggle("hidden", hide);
        });
    }

}