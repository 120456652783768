import { Controller } from "@hotwired/stimulus"
import Tagify from '@yaireo/tagify';
const debounce = require("lodash/debounce");

// Connects to data-controller="tagify"
export default class extends Controller {
  static values = { tags: Array, readOnly: Boolean, canEdit: Boolean };

  connect() {
    this.tagify = new Tagify(this.element, {
      editTags: {
        keepInvalid: false, // better to auto-remove invalid tags which are in edit-mode (on blur)
      },
      pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      whitelist: this.tagsValue,
      dropdown: {
        enabled: 0,
        maxItems: 20,
        position: "text",
      },
      enforceWhitelist: this.hasCanEditValue ? !this.canEditValue : false,
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(', ')
    });


    this.triggerChange = debounce(this.triggerChange, 200).bind(this);
    this.tagify.on('add', this.triggerChange.bind(this));
    this.tagify.on('remove', this.triggerChange.bind(this));
  }

  disconnect() {
    if (!this.tagify) return;

    this.tagify.destroy();
  }

  triggerChange(e) {
    this.element.dispatchEvent(new Event('tagchange'));
  }
}
